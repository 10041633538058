import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-reader';
import logo from '../logo/logo.png';
import { getUser, removeUserSession } from '../Utils/Common';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {useRef} from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Cookies from 'universal-cookie';
import styles from './Qrscan.module.css';
import r_esc from './r-escaneado.mp3'
import esc from './escaneado.mp3'
import ppe from './ppe.mp3'
import ev from './ev.mp3'
import a from './a.mp3'
import ppent from './ppent.mp3'
import e from './e.mp3'
import Escaneado from './Escaneado';
const Verify= () => {

	const navigate = useNavigate();
	
	useEffect(() => {
		if (!getUser()){
		 navigate("/")   
		}
	},[])
	
}

const cookies = new Cookies();
toast.configure()
const Qrscan = (props) => {

	Verify();
	// console.log('sesión '+getUser());


	const navigate = useNavigate();
	let btnRef = useRef();

	const user = sessionStorage.getItem('user')


	var data = '';
  	const handleLogout = () => {
		cookies.remove('user', {path: "/"})	
		removeUserSession();
		navigate('/');
	}

	// const useAudio = url => {
	// 	const [audio] = useState(new Audio(esc));
	// 	const [playing, setPlaying] = useState(false);
	  
	// 	const toggle = () => setPlaying(!playing);
	  
	// 	useEffect(() => {
	// 		playing ? audio.play() : audio.pause();
	// 	  },
	// 	  [playing]
	// 	);
	  
	// 	useEffect(() => {
	// 	  audio.addEventListener('ended', () => setPlaying(false));
	// 	  return () => {
	// 		audio.removeEventListener('ended', () => setPlaying(false));
	// 	  };
	// 	}, []);
	  
	// 	return [playing, toggle];
	//   };

	const success = () => {
		toast.success('Estado Modificado', {position: 'bottom-center', autoClose: 2000})
		let song = new Audio(esc);
		song.play()
	}
	const failed = () => {
		toast.error('Estado Modificado Recientemente', {position: 'bottom-center', autoClose: 2000})
		let song = new Audio(r_esc);
		song.play()
	}

    const [laser, setLaser] = useState()

	const [prueba, setPrueba] = useState("");

	var t = true;

	const [result, setResult] = useState('');

	const [bulto, setBulto] = useState('');

	const [id, setId] = useState('');
	const [pe, setPe] = useState('');
	const [pe_tel, setPeTel] = useState('');
	const [pr, setPr] = useState('');
	const [pr_tel, setPrTel] = useState('');

	const scnr = useFormInput('');
	
    let xd = ''

    const handleKeyDown = (e) => {
        if (e.key !== 'Unidentified' && e.key !== 'Shift' && e.key !== 'z') {
			if(['0','1','2','3','4','5','6','7','8','9'].includes(e.key)){
				xd += e.key
				console.log(xd)
			}
        }
        if (e.key === 'z') {
            setLaser(xd)

			const headers = { 
				'Content-Type': 'application/json'
			};
			var url = 'https://api-carga.tabsa.cl/api/qr/'+xd;
		//  var url = 'https://api-carga.tabsa.cl/api/qr/2';
		console.log('resultado')
		console.log(xd)
		 console.log(url)
		 axios.get(url, { headers }).then(response => {
			console.log(response.data.data.bulto)
			const b = response.data.data.bulto.item.nombre
			const r = response.data.data.estado.nombre
			setBulto(b)
			setResult(r)


			console.log('ultima modificación: '+response.data.data.last_update)
			// if(response.data.data.last_update < 301){
			// 	var sentence = failed();
			// }else{

			// }

			if(response.data.data.estado.id_estado == 1 || response.data.data.estado.id_estado == 2){
				toast.success('Preparado Para Envío', {position: 'bottom-center', autoClose: 2000})
				let song = new Audio(ppe);
				song.play()
			// }else if(response.data.data.estado.id_estado == 2){
			// 	toast.success('En Viaje', {position: 'bottom-center', autoClose: 2000})
			// 	let song = new Audio(ev);
			// 	song.play()
			// }else if(response.data.data.estado.id_estado == 3){
			// 	toast.success('Arribado', {position: 'bottom-center', autoClose: 2000})
			// 	let song = new Audio(a);
			// 	song.play()
			}else if(response.data.data.estado.id_estado == 4 && response.data.data.last_update < 300){
				toast.success('Preaprado para entrega', {position: 'bottom-center', autoClose: 2000})
				let song = new Audio(ppent);
				song.play()
			}else if(response.data.data.estado.id_estado == 5){
				toast.success('Entregado', {position: 'bottom-center', autoClose: 2000})
				let song = new Audio(e);
				song.play()
			}
			console.log(result);
			//  var url = 'https://api-carga.tabsa.cl/api/bulto/nuevo_estado/'+sessionStorage.getItem("bulto");
			 var url = 'https://api-carga.tabsa.cl/api/bulto/nuevo_estado/'+response.data.data.id_qr;

			 axios.get(url, { headers }).then(response => {
				console.log(response.data.data.bulto.item.nombre)
				const b = response.data.data.bulto.item.nombre
				setBulto(b)
				var url = 'https://api-carga.tabsa.cl/api/qr/'+response.data.data.id_qr;
				axios.get(url, { headers }).then(response => {
					console.log(url)
				   console.log(response.data.data.estado.nombre)
				   const r = response.data.data.estado.nombre
				   setResult(r)
				})
			})

			// sessionStorage.setItem("bulto", result);
		})

            xd = ''
        }
    }

    /** Con esto agregamos un eventListener para capturar las teclas presionadas y armar el código */
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [])

	const handleKeyPress = (event) => {
		console.log(scnr)
		console.log(event.key)
		if(event.key === 'z'){
			
		  console.log(scnr.value)
		  setPrueba("");
		}
	}

	const handdlePrueba = () => {

		var prueba = scnr.value
		console.log(prueba)
		console.log('aquí')
	}

	const handleError = (err) => {
		console.err(err)
	}

	const handleScan = (result) => {
		const headers = { 
			'Content-Type': 'application/json'
		};
		if(result){
		 var url = 'https://api-carga.tabsa.cl/api/qr/'+result;
		//  var url = 'https://api-carga.tabsa.cl/api/qr/2';
		 console.log(url)
		 axios.get(url, { headers }).then(response => {
			console.log(response.data.data)
			const b = response.data.data.bulto.item.nombre
			const r = response.data.data.estado.nombre
			setBulto(b)
			setResult(r)
			console.log('ultima modificación: '+response.data.data.last_update)
			if (response.data.data.last_update > 300){
				success();
			}else{
				failed();
			}
			changeStatus();

			// sessionStorage.setItem("bulto", result);
		})
			
		}
		// btnRef.current.setAttr<ibute("disabled", "disabled");

	}

	function changeStatus(xd){

		const headers = { 
			'Content-Type': 'application/json'
		};

		 console.log(result);

		//  var url = 'https://api-carga.tabsa.cl/api/bulto/nuevo_estado/'+sessionStorage.getItem("bulto");
		 var url = 'https://api-carga.tabsa.cl/api/bulto/nuevo_estado/'+xd;
		 console.log(url)
		 axios.get(url, { headers }).then(response => {
			console.log(response.data.data.bulto.item.nombre)
			const b = response.data.data.bulto.item.nombre
			setBulto(b)
			var url = 'https://api-carga.tabsa.cl/api/qr/'+response.data.data.id_qr;
			axios.get(url, { headers }).then(response => {
				console.log(url)
			   console.log(response.data.data.estado.nombre)
			   const r = response.data.data.estado.nombre
			   setResult(r)
			})
		})
	}

	function escaneoManual(){
        navigate('/scan/manual');
	}

	const previewStyle = {
		height: 320,
		width: 320,
		marginTop: 100,
	}
	console.log(result);
	

	return (
		<>
			<header>
				<img style={{marginTop: "10px", position: 'absolute'}} src={logo} className="logo"></img>
			</header>
			<div className={styles.container}>
				<input type="button" className='button2' onClick={handleLogout} value="Salir" />
				<br/><br/>
				{/* <h5 className={styles.user}>Sesión iniciada como: {user}</h5> */}
				{/* <QrReader
				delay={500}
				style={previewStyle}
				onError={handleError}
				onScan={handleScan}
				/> */}
				<div className={styles.result} style={{fontWeight: 'bolder'}}>Bulto: {bulto}</div> <br />
				<div className={styles.result} style={{fontWeight: 'bolder'}}>Estado: {result}</div> <br />
				<div>
				{/* <Alert severity="error">Sample Error Message</Alert> */}
				</div>
				<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
				{/* <div className={styles.result}>Valor Actual: {result.estado}</div> <br />
				<div className={styles.result}>Valor Actual: {result.nombre}</div> <br /> */}
				{/* <div ><button onClick={success}> Escaneado </button></div>
				<div ><button onClick={failed}> Recientemente Escaneado </button></div> */}
				{/* <Escaneado/> */}
				<button style={{'height': '60px','font-size': '16px','margin-top': '15px', 'background-color': '#facd01' , 'width': '40%', 'border-radius':'10px', 'border': 'none'}} onClick={escaneoManual}>Escaneo Manual</button>

			</div>
		</>
	);
}

const useFormInput = initialValue => {
	const [value, setValue] = useState(initialValue);
  
	const handleChange = e => {
	  setValue(e.target.value);
	}
	return {
	  value,
	  onChange: handleChange
	}
  }


export default Qrscan;