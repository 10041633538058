import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, NavLink, Router } from 'react-router-dom';
import axios from 'axios';

import Login from './Login/Login';
import Qrscan from './Qrscan/Qrscan';
import QrscanManual from './Qrscan/QrscanManual';

import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
import background from "./Images/bgv2.png";

import style from "./App.css";
import { getToken, getUser, removeUserSession, setUserSession } from './Utils/Common';
import Escaneado from './Qrscan/Escaneado';

function App() {
  const [authLoading, setAuthLoading] = useState(true);



  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }

    axios.get(`http://localhost:4000/verifyToken?token=${token}`).then(response => {
      setUserSession(response.data.token, response.data.user);
      setAuthLoading(false);
    }).catch(error => {
      removeUserSession();
      setAuthLoading(false);
    });
  }, []);



  if (authLoading && getToken()) {
    return <div className="content">Verificando Autenticación...</div>
  }


  return (
    <div className="App-component"
    style={{
      backgroundImage: 'url(http://recursos.tabsa.cl/storage/images/CRSj50Qe.png)',
      backgroundSize: "cover",
      height: "100vh",
      color: "#f5f5f5"
    }}>
      <BrowserRouter>
        <div>
          <div className="content">
          <Routes>
          <Route exact path="/" element={<Login/>} />
          <Route element={<PrivateRoute/>}>
            <Route path='/scan' element={<Qrscan/>}/>
          </Route>
          <Route element={<PrivateRoute/>}>
            <Route path='/scan/manual' element={<QrscanManual/>}/>
          </Route>
          <Route element={<PrivateRoute/>}>
            <Route path='/escaneado' element={<Escaneado/>}/>
          </Route>
        </Routes>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;