import React, { useState } from 'react';
import axios from 'axios';
import logo from '../logo/logo.png';
import { setUserSession } from '../Utils/Common';
import Cookies from 'universal-cookie';
import './Login.css';
import { useNavigate } from "react-router-dom";
import styles from "../styles.css"

function Login(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const username = useFormInput('');
  const password = useFormInput('');
  const [user, setUser] = useState('');
  const cookies = new Cookies();

  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    const auth = {
      // user : username.value,
      // password : password.value
      user : 'cargawilliams',
      password : 'Carwil1'
    }

    const headers = { 
    };
    axios.post('https://ldap.tabsa.cl/api/auth', auth, { headers }).then(response => {
      if(response.data.auth === 1){
        var respuesta = response.data
        cookies.set('user',respuesta.user, {path: "/"})
        console.log(response.data.auth)
        const auth = response.data.auth;
        setLoading(true);
        setUserSession(response.data.token, response.data.user, response.data.auth);
        setUser(response.data.user);
        navigate('/scan');
      }else{
        console.log(response.data);
        window.location.reload();
      }
    })
    console.log(axios.response);
    // console.log(getUserSession);
    // if(getUserSession().auth === 0){
    //   props.history.push('/');
    // }


  }

  // componentDidMount(){
  //   if (cookies.get('user')){
  //     navigate('/scan')
  //   }
  // }
  
  return (
    <>
      <header>
        <img style={{marginTop: "10px"}} src={logo} className="logo"></img>
      </header>
      <div className='container' >
        <h2 >Iniciar Sesión</h2>
          <br /><br />
        <div hidden>
          Usuario<br />
          <input type="text"  {...username} />
        </div>
        <div style={{ marginTop: 10 }} hidden>
          Contraseña<br />
          <input type="password" {...password} autoComplete="new-password" />
        </div>
        {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
        <input type="button" className='button' value={loading ? 'Ingresando...' : 'Ingresar'} onClick={handleLogin} disabled={loading} /><br />
      </div>
    </>
  );
}
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;