import React, { useState, useEffect } from "react";
import esc from './escaneado.mp3'

    const useAudio = url => {
    const [audio] = useState(new Audio(esc));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        // playing ? audio.play() : audio.pause();
        audio.play()
        },
        [playing]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
        audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return [playing, toggle];
    };

    const Escaneado = ({ url }) => {
    const [playing, toggle] = useAudio(url);

    return (
        <div>
        <button onClick={toggle}>{playing}</button>
        </div>
    );
    };

export default Escaneado;